<template>


  <!-- ***** Preloader Start ***** -->
  <div id="js-preloader" class="js-preloader">
    <div class="preloader-inner">
      <span class="dot"></span>
      <div class="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <!-- ***** Preloader End ***** -->

  <!-- Pre-header End -->

  <!-- ***** Header Area Start ***** -->
  <header class="header-area header-sticky wow slideInDown" style="z-index: 9999;" data-wow-duration="0.75s" data-wow-delay="0s">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <a href="/" class="logo">
              <img src="../assets/images/logo.png" style="height: 60px;width: 60px;" alt="">
            </a>
            <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** -->
            <ul class="nav">
              <li class="scroll-to-section"><a href="#top" class="active">Početak</a></li>
              <li class="scroll-to-section"><a href="#about">O Aplikaciji</a></li>
              <li class="scroll-to-section"><a href="#services">Usluge</a></li>
     
   
              <li class="scroll-to-section"><div class="border-first-button"><a href="/">Početna</a></div></li> 
            </ul>        
            <a class='menu-trigger'>
                <span>Menu</span>
            </a>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- ***** Header Area End ***** -->

  <div class="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <div class="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                <div class="row">
                  <div class="col-lg-12">
                    <h6>Staff</h6>
                    <h2>Organizirajte poslove u kampu</h2>
                    <p>Staff aplikacija osim što omogućuje osoblju lakšu komunikaciju, ona služi kao sučelje koje povezuje djelatnike sa gostom.</p>
                  </div>
                  <div class="col-lg-12">
                    <div class="border-first-button scroll-to-section">
                      <a href="#about">Više</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                <img src="../assets/images/fix.png" alt="" style="width:50%;height: auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="about" class="about section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
          
            <div class="col-lg-12 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div class="about-right-content">
                <div class="section-heading">
                  <h6></h6>
                  <h4>O Aplikaciji<em></em></h4>
                  <div class="line-dec"></div>
                </div>
                <p>Aplikacije su stvorene kako bi se olakšali neki poslovi unutar kampa.
                  <br>
   
                  
                  Naš tim će stvoriti personalizirane aplikacije. Aplikacije su dostupne za Android, Ios i Web te ćemo ih mi objaviti i održavati za vas
                  <br>
                  Bilo koji dodatan sadržaj nadopuniti ćemo u vaše aplikacije<br>
    
                  U sljedećoj sekciji saznajte više o uslugama koje može vaša aplikacija poprimiti
                </p>
                <!--
                <div class="row">
                  <div class="col-lg-4 col-sm-4">
                    <div class="skill-item first-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                      <div class="progress" data-percentage="90">
                        <span class="progress-left">
                          <span class="progress-bar"></span>
                        </span>
                        <span class="progress-right">
                          <span class="progress-bar"></span>
                        </span>
                        <div class="progress-value">
                          <div>
                            90%<br>
                            <span>Coding</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-4">
                    <div class="skill-item second-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                      <div class="progress" data-percentage="80">
                        <span class="progress-left">
                          <span class="progress-bar"></span>
                        </span>
                        <span class="progress-right">
                          <span class="progress-bar"></span>
                        </span>
                        <div class="progress-value">
                          <div>
                            80%<br>
                            <span>Photoshop</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-4">
                    <div class="skill-item third-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                      <div class="progress" data-percentage="80">
                        <span class="progress-left">
                          <span class="progress-bar"></span>
                        </span>
                        <span class="progress-right">
                          <span class="progress-bar"></span>
                        </span>
                        <div class="progress-value">
                          <div>
                            80%<br>
                            <span>Animation</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="services" class="services section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <h6>Naše usluge</h6>
            <h4>Što Staff aplikacija <em>olakšava</em></h4>
            <div class="line-dec"></div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="naccs">
            <div class="grid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="menu">
                    <div class="first-thumb active">
                      <div class="thumb">
                        <span class="icon"><i class="fa fa-wrench iconn"></i></span>
                        Zadaci
                      </div>
                    </div>
                    <div>
                      <div class="thumb">                 
                        <span class="icon"><i class="fa fa-pizza-slice iconn"></i></span>
                        Dostave 
                      </div>
                    </div>
                    <div>
                      <div class="thumb">                 
                        <span class="icon"><i class="fa fa-user iconn"></i></span>
                        Rezervacije
                      </div>
                    </div>
                    <div>
                      <div class="thumb">                 
                        <span class="icon"><i class="fa fa-home iconn"></i></span>
                        Status
                      </div>
                    </div>
                    <div>
                      <div class="thumb">                 
                        <span class="icon"><i class="fa-solid fa-lock iconn" ></i></span>
                        Admin
                      </div>
                    </div>
                    <div>
                      <div class="thumb">                 
                        <span class="icon"><i class="fa fa-plus iconn" ></i></span>
                        Ostalo
                      </div>
                    </div>
                    
                    
                  
                  </div>
                </div> 
                <div class="col-lg-12">
                  <ul class="nacc">
                    <li class="active">
                      <div>
                        <div class="thumb">
                          <div class="row">
                            <div class="col-lg-6 align-self-center">
                              <div class="left-text">
                                <h4>Zadaci &amp; Kvarovi</h4>
                                <p>Aplikacija omogućuje osoblju kampa jednostavno upravljanje zadacima. Osoblje može dodavati, dodjeljivati i odrađivati zadatake. Ova funkcionalnost olakšava raspodjelu radnih obaveza među članovima osoblja i osigurava učinkovitu koordinaciju.</p>
                               
                                <p>Djelatnici mogu pregledati zadatke koje su stvorili gosti kroz Guest aplikaciju</p>
                                <p>Svaki djelatnik ima kontrolnu ploču gdje im se pokazuju svi njihovi zadaci</p>
                              </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                              <div class="right-image">
                                <img src="../assets/images/zadaci.png" style="width:100%;" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div class="thumb">
                          <div class="row">
                            <div class="col-lg-6 align-self-center">
                              <div class="left-text">
                                <h4>Dostave hrane</h4>
                                <p>Djelatnici restorana vide sve dostave i kada
ju potvrde, vidljive su i dostavljačima
Dostavljači imaju pregled svih dostava.
Mogu ih preuzeti ili na kraju označiti kao
dostavljene</p>
                               
                        
                              </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                              <div class="right-image">
                                <img src="../assets/images/s11.png" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div class="thumb">
                          <div class="row">
                            <div class="col-lg-6 align-self-center">
                              <div class="left-text">
                                <h4>Rezervacije</h4>
                                <p>Pregled rezervacija sportskih terena i masaža.
Ovu sezonu većinu rezervacija su samostalno napravili
gosti kroz aplikaciju.
Prije Mp aplikacija koristio se Ms Excel za termine.
Excel nije bio prikladan za vođenje masaža jer razne
vrste imaju drugačije vrijeme trajanja.
Uz rezervacije sporta, osoblje može kreirati barkodove
za ulaz na terene.
</p>
        
                              </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                              <div class="right-image">
                                <img src="../assets/images/s10.png" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div class="thumb">
                          <div class="row">
                            <div class="col-lg-6 align-self-center">
                              <div class="left-text">
                                <h4>Status</h4>
                                <p>Kada gost otiđe boja je crvena što znači da kućicu nije
čista.
Recepcija tada može dodati email gosta.
Kada domaćinstvo označi da je kućica čista, gost
dobiva email podsjetnik kako može preuzeti ključeve
na recepciji.
Uz email podsjetnik nalaze se informacije o MpGuest
aplikaciji.
Klikom na + ikonu označuje je li u kućici krevetić ili
hranilica kako bi domaćinstvo lakše vodilo evidenciju.</p>
                              </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                              <div class="right-image">
                                <img src="../assets/images/s12.png" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div>
                        <div class="thumb">
                          <div class="row">
                            <div class="col-lg-6 align-self-center">
                              <div class="left-text">
                                <h4>Adminsko sučelje</h4>
                                <p>Svi podaci sustava aplikacija mogu se
dodavati i uređivati u ovom sučelju.
Mogu se kreirati obavijesti za goste,
uređivati oglasna ploča, dodavati nove
djelatnike, pregledavati statistika dostave
hrane i kvarova itd.</p>
                               
                              </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                              <div class="right-image">
                                <img src="../assets/images/admin.png" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                   
                    </li>



                    <li>
                    <div>
                        <div class="thumb">
                          <div class="row">
                            <div class="col-lg-6 align-self-center">
                              <div class="left-text">
                                <h4>Ostalo</h4>
                                <p><b style="color:gray;font-weight: bold;">Obavijesti</b><br>Osoblje si može međusobno prikvačiti
obavijesti na određene grupe ili za sve.
Npr. recepcija može stražarima stvoriti
obavijest za svakog gosta koji odlazi
tijekom noći.
Majstori mogu obavijestiti recepciju o
budućim kvarovima kako bi znali
informirati goste.
<br><br>
<b style="color:gray;font-weight: bold;">Izgubljeno & Pronađeno</b> <br>

Lako vođenje evidencija o izgubljenim
stvarima.
Osoblje može unijeti email na koji će se
poslati podsjetnik kada se označi da je
predmet pronađen
</p>
                               
                              </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                              <div class="right-image">
                                <img src="../assets/images/predmeti.png" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </li>


                  </ul>
                </div>          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  

</template>



<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  
  mounted(){
    

    $(".menu-trigger").on('click', function() { 
      $(this).toggleClass('active');
      $('.header-area .nav').slideToggle(200);
    });
  





  }
}
</script>



<style>

.iconn{
  font-size:50px;
  color:#17a2b8;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {  
  .iconn{
  font-size:35px;

}
    
   
    }  
    

/*

TemplateMo 568 DigiMedia

https://templatemo.com/tm-568-digimedia

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 50%;
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #fff;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 30px;
  font-weight: 300;
  color: #afafaf;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #17a2b8;
  color: #fff !important;
}

::-moz-selection {
  background: #17a2b8;
  color: #fff !important;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.page-section {
  margin-top: 120px;
}

.section-heading {
  position: relative;
  z-index: 2;
}


.section-heading h6 {
  font-size: 15px;
  font-weight: 700;
  color: #17a2b8;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.section-heading h4 {
  color: #2a2a2a;
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.section-heading h4 em {
  font-style: normal;
  color: #17a2b8;
}

.section-heading .line-dec {
  width: 50px;
  height: 2px;
  background-color: #17a2b8;
}

.border-first-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all .5s;
}

.border-first-button a:hover {
  background-color: #17a2b8;
  color: #fff !important;
}



/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.pre-header {
  background-color: #efefef;
  height: 60px;
  padding: 15px 0px;
}

.pre-header ul li {
  display: inline-block;
}

.pre-header ul.info li {
  margin-right: 45px;
}

.pre-header ul.info li a {
  color: #afafaf;
  font-size: 14px;
  transition: all .3s;
}

.pre-header ul.info li a:hover {
  color: #17a2b8;
}

.pre-header ul.info li a i {
  font-size: 18px;
  margin-right: 8px;
}

.pre-header ul.social-media {
  text-align: right;
}

.pre-header ul.social-media li {
  margin-left: 5px;
}

.pre-header ul.social-media li a {
  background-color: #afafaf;
  color: #fff;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  transition: all .3s;
}

.pre-header ul.social-media li a:hover {
  background-color: #17a2b8;
}

.background-header {
  background-color: #fff!important;
  height: 80px!important;
  position: fixed!important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 5px 8px rgba(0,0,0,0.03);
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #fff;
}

.background-header .main-nav .nav li:hover a {
  color: #17a2b8;
}

.background-header .nav li a.active {
  position: relative;
  color: #fff;
}

.background-header .nav li a.active:after {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #17a2b8;
  content: '';
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}

.background-header .nav li:last-child a.active:after {
  background-color: transparent;
}

.header-area {
  background-color: #fff;
  box-shadow: 0px 5px 8px rgba(0,0,0,0.03);
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 100px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo {
    line-height: 100px;
    float: left;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.background-header .main-nav .logo {
  line-height: 80px;
}

.background-header .main-nav .nav {
  margin-top: 20px !important;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 30px;
  margin-right: 0px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
  padding-left: 40px;
}

.header-area .main-nav .nav li:last-child a ,
.background-header .main-nav .nav li:last-child a {
  padding: 0px 20px !important;
  font-weight: 400;
}

.header-area .main-nav .nav li:last-child a:hover ,
.background-header .main-nav .nav li:last-child a:hover {
  color: #fff !important;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #2a2a2a;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #17a2b8 !important;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #17a2b8 !important;
  opacity: 1;
}

.header-area .main-nav .nav li.submenu {
  position: relative;
  padding-right: 30px;
}

.header-area .main-nav .nav li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #2a2a2a;
  position: absolute;
  right: 18px;
  top: 12px;
}

.background-header .main-nav .nav li.submenu:after {
  color: #2a2a2a;
}

.header-area .main-nav .nav li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 50px;
  opacity: 0;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a!important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #17a2b8 !important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #2a2a2a;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #2a2a2a;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #2a2a2a;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #2a2a2a;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #2a2a2a;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area .nav {
  margin-top: 30px;
}

.header-area.header-sticky .nav li a.active {
  color: #17a2b8;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav .nav li:last-child  ,
  .background-header .main-nav .nav li:last-child {
    display: none;
  }
  .header-area .main-nav .nav li:nth-child(6),
  .background-header .main-nav .nav li:nth-child(6) {
    padding-right: 0px;
  }
  .background-header .nav li a.active:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .pre-header ul.info li:last-child {
    display: none;
  }
  .background-header .main-nav .nav {
    margin-top: 80px !important;
  }
  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #17a2b8 !important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #fff;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
    box-shadow: 0px 5px 8px rgba(0,0,0,0.03);
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .background-header .nav {
    margin-top: 80px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #17a2b8 !important;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    color: #3B566E;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/

.js-preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    z-index: 9999;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.js-preloader.loaded {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

@-webkit-keyframes dot {
    50% {
        -webkit-transform: translateX(96px);
        transform: translateX(96px);
    }
}

@keyframes dot {
    50% {
        -webkit-transform: translateX(96px);
        transform: translateX(96px);
    }
}

@-webkit-keyframes dots {
    50% {
        -webkit-transform: translateX(-31px);
        transform: translateX(-31px);
    }
}

@keyframes dots {
    50% {
        -webkit-transform: translateX(-31px);
        transform: translateX(-31px);
    }
}

.preloader-inner {
    position: relative;
    width: 142px;
    height: 40px;
    background: #fff;
}

.preloader-inner .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    background: #17a2b8;
    border-radius: 50%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: dot 2.8s infinite;
    animation: dot 2.8s infinite;
}

.preloader-inner .dots {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    -webkit-animation: dots 2.8s infinite;
    animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    background: #17a2b8;
    border-radius: 50%;
}



/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/

.main-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 226px 0px 120px 0px;
  position: relative;
  overflow: hidden;
}

.main-banner:after {
  content: '';
  background-image: url(../assets/images/slider-left-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 60px;
  width: 262px;
  height: 625px;
  z-index: 1;
}

.main-banner:before {
  content: '';
  background-image: url(../assets/images/slider-right-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 60px;
  width: 1159px;
  height: 797px;
  z-index: -1;
}

.main-banner .left-content {
  margin-right: 15px;
}

.main-banner .left-content h6 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  color: gray;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.main-banner .left-content h2 {
  z-index: 2;
  position: relative;
  font-weight: 700;
  font-size: 50px;
  color: #2a2a2a;
  margin-bottom: 20px;
}

.main-banner .left-content p {
  margin-bottom: 30px;
  margin-right: 45px;
}

.main-banner .right-image {
  text-align: right;
  position: relative;
  z-index: 20;
}

.main-banner .right-image img {
  max-width: 593px;
}



/* 
---------------------------------------------
About Style
--------------------------------------------- 
*/

#about {
  padding-top: 130px;
}

.about-left-image img {
  margin-right: 45px;
}

.about-right-content p {
  margin-top: 30px;
  margin-bottom: 45px;
}

.skills-content {
  position: relative;
  z-index: 1;
  margin-top: -50px;
  background-color: #f5f5f5;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 110px 0px 50px 0px;
}

.skill-item {
  text-align: center;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #fd6a54;
}

.first-skill-item .progress .progress-bar {
  border-color: #17a2b8;
}

.second-skill-item .progress .progress-bar {
  border-color: #726ae3;
}

.third-skill-item .progress .progress-bar {
  border-color: #f58b56;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress .progress-value {
  text-align: center;
  color: #2a2a2a;
  display: flex;
  width: 100%;
  border-radius: 50%;
  font-size: 35px;
  text-align: center;
  line-height: 25px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
}
.progress .progress-value div {
  margin-top: 10px;
}
.progress .progress-value span {
  font-size: 18px;
  text-transform: none;
  color: #afafaf;
  font-weight: 300;
}

/* This for loop creates the  necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
.progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}
.progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}
.progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}
.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
}
.progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 1.5s linear forwards 1.5s;
}

.progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.5s;
}

.progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
}

.progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 1.5s linear forwards 1.5s;
}

.progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}
.progress {
  margin-bottom: 1em;
}

/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.services {
  padding-top: 130px;
  position: relative;
}

.services:after {
  content: '';
  background-image: url(../assets/images/services-left-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0px;
  width: 786px;
  height: 1217px;
  z-index: 0;
}

.services:before {
  content: '';
  background-image: url(../assets/images/services-right-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 400px;
  width: 161px;
  height: 413px;
  z-index: 0;
}

.services .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.services .section-heading .line-dec {
  margin: 0 auto;
}

.services .naccs {
  position: relative;
  z-index: 1;
}

.services .icon {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.services .naccs .menu div h4 {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
}

.services .icon img {
  margin-bottom: 10px;
  max-width: 60px;
  min-width: 60px;
}

.services .naccs .menu {
  text-align: center;
  margin-bottom: 30px;
}

.services .naccs .menu div {
  color: #2a2a2a;
  margin: 0px;
  width: 15%;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 15px;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.services .naccs .menu div .thumb {
  display: inline-block;
  width: 100%;
  padding: 30px 0px;
  background-color: #fff;
}

.services .naccs .menu div.active {
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

.services ul.nacc {
  height: 100% !important;
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.services ul.nacc li {
  opacity: 0;
  transform: translateX(-50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.services ul.nacc li.active {
  transition-delay: 0.3s;
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  background-color: #fff;
  border-radius: 15px;
  padding: 80px 120px 50px 120px;
}

.services ul.nacc li {
  width: 100%;
}

.services ul.nacc li .right-image img {
  max-width: 420px;
  float: right;
}

.services .nacc .thumb h4 {
  color: #2a2a2a;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 25px;
}

.services .nacc .thumb .main-white-button {
  text-align: right;
  margin-top: 40px;
}

.services .nacc .thumb .main-white-button a {
  background-color: #8d99af;
  color: #fff;
}

.services .nacc .thumb .main-white-button a i {
  background-color: #fff;
  color: #8d99af;
}

.services .left-text h4 {
  font-size: 20px;
  font-weight: 700;
  color: #17a2b8 !important;
}

.services .left-text p {
  margin-bottom: 30px;
}

.nacc .ticks-list span {
  display: inline-block;
  opacity: 1;
  margin-right: 45px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 400;
}



/* 
---------------------------------------------
Free Quote
--------------------------------------------- 
*/

.free-quote {
  background-image: url(../assets/images/quote-bg-v2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 120px 0px;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 130px;
}

.free-quote .section-heading {
  margin-bottom: 60px;
}

.free-quote .section-heading h6,
.free-quote .section-heading h4 {
  color: #fff;
}

.free-quote .section-heading .line-dec {
  margin: 0 auto;
  background-color: #fff;
}

.free-quote form {
  background-color: #fff;
  display: inline-block;
  width: 100%;
  min-height: 80px;
  border-radius: 40px;
  position: relative;
  z-index: 1;
}

.free-quote form input {
  width: 100%;
  margin-top: 20px;
  margin-left: 30px;
  color: #afafaf;
  font-weight: 400;
  font-size: 15px;
  height: 40px;
  background-color: transparent;
  border-bottom: 1px solid #eee;
  border-top: none;
  border-left: none;
  border-right: none;
  position: relative;
  z-index: 2;
  outline: none;
}

.free-quote form button {
  width: 100%;
  height: 80px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  outline: none;
  border: none;
  margin-left: 30px;
  background-color: #17a2b8;
  font-size: 15px;
  color: #fff;
}


/* 
---------------------------------------------
Portfolio
--------------------------------------------- 
*/

.our-portfolio {
  padding-top: 130px;
  overflow: hidden;
  position: relative;
}

.our-portfolio:before {
  content: '';
  background-image: url(../assets/images/portfolio-right-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 0px;
  width: 414px;
  height: 861px;
  z-index: 1;
}

.our-portfolio:after {
  content: '';
  background-image: url(../assets/images/portfolio-left-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0px;
  width: 677px;
  height: 759px;
  z-index: 1;
}

.our-portfolio .section-heading {
  margin-bottom: 80px;
}

.our-portfolio .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  z-index: 2;
}

.our-portfolio .item {
  position: relative;
  z-index: 222;
}

.portfolio-item {
  border-radius: 25px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  margin: 15px;
}

.portfolio-item .thumb {
  position: relative;
  border-radius: 50px;
}

.portfolio-item:hover .down-content h4,
.portfolio-item:hover .down-content span {
  color: #17a2b8;
}

.portfolio-item .thumb img {
  border-top-right-radius: 23px;
  border-top-left-radius: 23px;
  overflow: hidden;
}

.portfolio-item .down-content {
  background-color: #fff;
  text-align: center;
  padding: 18px 0px;
  border-bottom-right-radius: 23px;
  border-bottom-left-radius: 23px;
}

.portfolio-item .down-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 8px;
  transition: all .3s;
}

.portfolio-item .down-content span {
  font-size: 15px;
  color: #afafaf;
  transition: all .3s;
}

.our-portfolio .owl-nav {
  display: inline-block!important;
  position: absolute;
  top: -125px;
  right: 15%;
  max-width: 1320px;
}

.our-portfolio .owl-nav .owl-next {
  margin-left: 10px;
}

.our-portfolio .owl-nav span {
  width: 46px;
  height: 46px;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  font-size: 30px;
  background-color: #eee;
  border-radius: 50%;
  color: #fff;
  transition: all 0.5s;
}

.our-portfolio .owl-nav span:hover {
  color: #fff;
  background-color: #17a2b8;
}


/* 
---------------------------------------------
Blog
--------------------------------------------- 
*/

.blog:before {
  content: '';
  background-image: url(../assets/images/blog-left-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0px;
  width: 961px;
  height: 1020px;
  z-index: 0;
}

.blog {
  position: relative;
  padding-top: 130px;
}

.blog .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.blog .section-heading .line-dec {
  margin: 0 auto;
}

.blog-post {
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  border-radius: 25px;
}

.show-up {
  position: relative;
  z-index: 200;
}

.blog-post .thumb img {
  border-top-right-radius: 23px;
  border-top-left-radius: 23px;
}

.blog-post .down-content {
  border-bottom-right-radius: 23px;
  border-bottom-left-radius: 23px;
  background-color: #fff;
  padding: 30px;
}

.blog-post .down-content span.category {
  font-size: 15px;
  color: #fff;
  padding: 8px 12px;
  background-color: #17a2b8;
  border-radius: 18px;
  display: inline-block;
}

.blog-post .down-content span.date {
  font-size: 15px;
  color: #afafaf;
  text-align: right;
  float: right;
  margin-top: 4px;
}

.blog-post .down-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 30px;
}

.blog-post .down-content p {
  margin-bottom: 30px;
}

.blog-post .down-content span.author {
  font-size: 15px;
  color: #2a2a2a;
}

.blog-post .down-content span.author img {
  max-width: 56px;
  border-radius: 50%;
  margin-right: 15px;
}

.blog-post .down-content .border-first-button {
  display: inline-block;
  float: right;
}

.blog-posts {
  margin-left: 30px;
}

.post-item {
  margin-bottom: 62px;
}

.last-post-item {
  margin-bottom: 0px;
}

.post-item .thumb {
  display: inline-block;
  float: left;
  margin-right: 30px;
}

.post-item .thumb img {
  border-radius: 23px;
  display: inline-block;
}

.post-item .right-content {
  padding-top: 20px;
}

.post-item .right-content span.category {
  font-size: 15px;
  color: #fff;
  padding: 8px 12px;
  background-color: #17a2b8;
  border-radius: 18px;
  display: inline-block;
}

.post-item .right-content span.date {
  font-size: 15px;
  color: #afafaf;
  text-align: right;
  float: right;
  margin-top: 4px;
}

.post-item .right-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 30px;
}


/* 
---------------------------------------------
contact
--------------------------------------------- 
*/

.contact-us {
  padding-top: 130px;
}

.contact-us .section-heading .line-dec {
  margin: 0 auto;
}

.contact-us .section-heading {
  text-align: center;
  margin-bottom: 80px;
}


form#contact:before {
  background-image: url(../assets/images/contact-top-right-v2.png);
  position: absolute;
  right: 0;
  top: 0;
  width: 726px;
  height: 78px;
  background-repeat: no-repeat;
  content: '';
  z-index: 1;
}

.contact-dec img {
  max-width: 224px;
  position: absolute;
  right: 25px;
  top: -242px;
}

form#contact:after {
  background-image: url(../assets/images/contact-bottom-right-v2.png);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 532px;
  height: 106px;
  background-repeat: no-repeat;
  content: '';
  z-index: 1;
}

form#contact {
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  position: relative;
  background-color: #fff;
  border-radius: 23px;
  text-align: center;
}

form#contact #map iframe {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  margin-bottom: -7px;
  position: relative;
  z-index: 2;
}

.fill-form {
  padding: 80px 60px 80px 30px;
}

.fill-form .info-post {
  margin-bottom: 20px;
}

.fill-form .icon {
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  border-radius: 23px;
  padding: 25px 15px;
}

.fill-form .icon img {
  max-width: 60px;
  display: block;
  margin: 0 auto;
}

.fill-form .icon a {
  margin-top: 15px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #2a2a2a;
  transition: all .3s;
}

.fill-form .icon:hover a {
  color: #17a2b8;
}

form#contact input {
  width: 100%;
  height: 46px;
  background-color: transparent;
  border: 1px solid #eee;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: #2a2a2a;
  padding: 0px 20px;
  border-radius: 23px;
  margin-top: 30px;
}

form#contact input::placeholder {
  color: #aaa;
}

form#contact textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 200px;
  min-height: 200px;
  height: 200px;
  border-radius: 23px;
  background-color: transparent;
  border: 1px solid #eee;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: #2a2a2a;
  padding: 15px 20px;
  margin-top: 30px;
}

form#contact textarea::placeholder {
  color: #aaa;
}

form#contact button {
  display: inline-block;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #17a2b8;
  margin-top: 30px;
  width: 100%;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
  border: 1px solid #17a2b8;
  transition: all .3s;
  outline: none;
}

form#contact button:hover {
  background-color: #17a2b8 !important;
  color: #fff!important;
}

/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer {
  background-image: url(../assets/images/footer-bg-v2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 130px;
}

footer p {
  text-align: center;
  margin: 20px 0px;
  color: #fff;
}

footer p a {
  color: #fff;
  transition: all .5s;
}

/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1200px) {
  .header-area .main-nav .logo h4 {
    font-size: 24px;
  }
  .header-area .main-nav .logo h4 img {
    max-width: 25px;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:last-child {
    padding-left: 20px;
  }
}

@media (max-width: 992px) {
  form#contact {
    overflow: hidden;
  }
  .header-area .main-nav .logo h4 {
    font-size: 20px;
  }
  .main-banner .left-content {
    margin-right: 0px;
  }
  .main-banner {
    text-align: center;
    padding: 226px 0px 30px 0px;
  }
  .main-banner:before {
    display: none;
  }
  .main-banner .right-image {
    margin: 30px auto 0px auto;
    text-align: center;
  }
  .features-item {
    margin-bottom: 45px;
  }
  .last-features-item,
  .last-skill-item {
    margin-bottom: 0px !important;
  }
  .skill-item {
    margin-bottom: 30px;
  }
  .about-left-image img {
    margin-right: 0px;
    margin-bottom: 45px;;
  }
  .services .naccs .menu div {
    font-size: 15px;
    font-weight: 500;
  }
  .service-item {
    text-align: center;
  }
  .service-item .icon {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .about-us .left-image {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 45px;
  }
  .blog-posts {
    margin-left: 0px;
    margin-top: 30px;
  }
  .post-item {
    margin-bottom: 70px;
  }
  .our-portfolio .owl-nav {
    display: none !important;
  }
  .contact-info {
    margin-top: 60px;
  }
  form#contact {
    padding: 45px;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo h4 {
    font-size: 30px;
  }
  .header-area .main-nav .logo h4 img {
    max-width: 30px;
    margin-left: 5px;
  }
  .main-banner .info-stat {
    margin-bottom: 15px;
  }
  .service-item {
    text-align: center;
    padding: 30px;
  }
  .service-item .icon {
    float: none;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .service-item .right-content {
    display: inline-block;
  }
  .services .naccs .menu div .thumb {
    padding: 5px;
  }
  .services .icon img {
    margin: 0px;
  }
  .services ul.nacc li.active {
    padding: 45px;
  }
  .services .naccs .menu div  {
    font-size: 0px;
  }
  .services ul.nacc li .right-image img {
    float: none;
  }
  .our-portfolio .section-heading,
  .about-us .section-heading,
  .about-us .about-item,
  .about-us p,
  .about-us .main-green-button {
    text-align: center;
  }
  .our-portfolio .section-heading .line-dec {
    margin: 0 auto;
  }
  .our-services .section-heading {
    margin-left: 15px;
    margin-right: 15px;
  }
  .free-quote form input {
    margin-left: 0px;
    padding: 0px 30px;
  }
  .free-quote form button {
    margin-left: 0px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 0px;
  }
  .blog-posts {
    margin-left: 0px;
    margin-top: 30px;
  }
  .post-item {
    margin-bottom: 30px;
  }
  .post-item .thumb img {
    max-width: 140px;
  }
  .post-item .right-content p {
    display: none;
  }
  .about-us .about-item {
    margin-top: 15px;
  }
  form#contact {
    padding: 30px;
  }
}

</style>